import React from "react";
import cn from 'classnames';

type LoaderProps = {
    size?: number;
    inline?: boolean;
}

export const Loader = ({ size = 28, inline = false }: LoaderProps) => {
    return <div className={cn("loader loader--spinner", inline && "inlineLoader")}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke="#434b4f"
                strokeWidth="4"
                r="35"
                strokeDasharray="164.93361431346415 56.97787143782138"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                ></animateTransform>
            </circle>
        </svg>
    </div>
}