import React from "react";
import { ButtonResult, ButtonType, Design, PopupSize, QuantitySelector, RemanufacturedChoice, addRemanAndDepositItemsToCart, popup, useCartItemUpdate } from "ui";
import { RemanResults } from "ui/src/types";
import * as styles from "./app.module.scss";

function App() {
    const state = window.app.preloadState.quantitySelector;
    const update = useCartItemUpdate(state.code);

    const onChange = async (quantity: number) => {
        await update(quantity);
        state.remanQuantity = 0;
    }

    const onRemove = async (f: () => void) => { await onChange(0); f() }
    const translations = (window as any).app.preloadState.translation;

    const showRemanufacturePopUp = async () => {

        let result: RemanResults[string] = undefined!;

        const changeResult = (results: RemanResults) => {
            result = results[state.code];
        }

        const override = state.remanQuantity
            ? {
                [state.code]: state.remanQuantity
            } : undefined;

        const popupResult = await popup(
            translations["remanufacturedItemPopUp.selectItemToAddToCart"],
            <RemanufacturedChoice changeResult={changeResult} sparePartIds={[state.code]} quantityOverride={override} />,
            [
                { label: translations["remanufacturedItemPopUp.remove"], result: ButtonResult.No, type: ButtonType.Link, className: "deleteButton", visible: override != null },
                { label: translations["remanufacturedItemPopUp.cancel"], result: ButtonResult.Cancel, type: ButtonType.Outlined },
                { label: translations["remanufacturedItemPopUp.ok"], result: ButtonResult.Ok, type: ButtonType.Primary }
            ],
            PopupSize.ExtraLarge,
            "noPaddingsPopUp"
        );

        if (popupResult == ButtonResult.Ok) {
            return result;
        } else if (popupResult == ButtonResult.No) {
            return {
                selectedReman: false,
                sparePartQuantity: 0,
                remanQuantity: 0
            };
        } else {
            return undefined;
        }
    }

    const onAddReman = async (quantity: number) => {
        await addRemanAndDepositItemsToCart(state.code, quantity);
        state.remanQuantity = quantity;
    }

    return <QuantitySelector
        onAddReman={onAddReman}
        showRemanufacturePopUp={state.hasRemanItem ? showRemanufacturePopUp : undefined}
        forceMinValue={false}
        {...state}
        hasRemanInCart={!!state.remanQuantity}
        onChange={onChange}
        onRemove={onRemove}
        hasRemanAvailable={state.hasRemanItem}
        design={Design.PDP}
    />
}

export default App;
